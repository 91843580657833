export const indexPage = () => {
	return "/dashboard";
};

export const getEventoDadoLabel = key => {
	switch (key) {
		case 'ved_obs':
			return 'Observação';
		case 'ved_checklist_troca_lona_freio_cavalo':
			return 'Componentes trocados';
		case 'ved_checklist_troca_lona_freio_reboque':
			return 'Componentes trocados';
		default:
			return '';
	}
};

export const getEventoDadoValue = (key, value) => {
	let retorno = '';

	switch (key) {
		case 'ved_checklist_troca_lona_freio_cavalo':
			if (value.dianteira) {
				retorno = 'Dianteira';
			}

			if (value.tracao) {
				retorno = retorno != '' ? (retorno + ', Tração') : 'Tração';
			}

			if (value.truck) {
				retorno = retorno != '' ? (retorno + ', Truck') : 'Truck';
			}

			return retorno;
		case 'ved_checklist_troca_lona_freio_reboque':
			if (value.eixo_1) {
				retorno = 'Eixo 1';
			}

			if (value.eixo_2) {
				retorno = retorno != '' ? (retorno + ', Eixo 2') : 'Eixo 2';
			}

			if (value.eixo_3) {
				retorno = retorno != '' ? (retorno + ', Eixo 3') : 'Eixo 3';
			}

			return retorno;
		default:
			return value ? value : "N/A";
	}
};

export const countOccurrencesWithProportion = (arr, property, sum, multiplier, divider) => {
	const occurrences = {};

	for (let i = 0; i < arr.length; i++) {
		const obj = arr[i];
		const propertyValue = obj[property];
		let propertyKey = null;
		let name = '';

		if (propertyValue) {
			propertyKey = `${propertyValue}`;
		} else if (obj['cmf_forma_pagamento']){
			propertyKey = obj['cmf_forma_pagamento'];
		} else {
			propertyKey = obj['cmf_tipo'];
		}

		if (obj?.forma_pagamento) {
			name = obj['forma_pagamento'];
		} else if (obj.cmf_forma_pagamento) {
			name = formatFormaPagamento[[obj['cmf_forma_pagamento']]]
		} else {
			name = obj.cmf_tipo == 'CREDITO' ? 'Crédito (Entrada Manual)' : 'Débito (Entrada Manual)';
		}
		
		if (occurrences.hasOwnProperty(propertyKey)) {
			occurrences[propertyKey].name = name;
			occurrences[propertyKey].count++;
			occurrences[propertyKey].sum += obj[divider] > 0 ? (obj[sum] * obj[multiplier]) / obj[divider] : 0;
		} else {
			occurrences[propertyKey] = {
				name: name,
				count: 1,
				sum: obj[divider] > 0 ? (obj[sum] * obj[multiplier]) / obj[divider] : 0
			};
		}
	}

	const result = [];

	for (let key in occurrences) {
		if (occurrences.hasOwnProperty(key)) {
			const value = key;
			const { name, count, sum } = occurrences[key];
			result.push({ name, value, count, sum });
		}
	}

	return result;
};

export const countOccurrences = (arr, property, sum) => {
	const occurrences = {};

	for (let i = 0; i < arr.length; i++) {
		const obj = arr[i];
		const propertyValue = obj[property];
		let propertyKey = null;
		let name = '';

		if (propertyValue) {
			propertyKey = `${propertyValue}`;
		} else if (obj['cmf_forma_pagamento']){
			propertyKey = obj['cmf_forma_pagamento'];
		} else {
			propertyKey = obj['cmf_tipo'];
		}

		if (obj?.forma_pagamento) {
			name = obj['forma_pagamento'];
		} else if (obj.cmf_forma_pagamento) {
			name = formatFormaPagamento[[obj['cmf_forma_pagamento']]]
		} else {
			name = obj.cmf_tipo == 'CREDITO' ? 'Crédito (Entrada Manual)' : 'Débito (Entrada Manual)';
		}

		if (occurrences.hasOwnProperty(propertyKey)) {
			occurrences[propertyKey].name = name;
			occurrences[propertyKey].count++;
			occurrences[propertyKey].sum += obj[sum];
		} else {

			occurrences[propertyKey] = {
				name: name,
				count: 1,
				sum: obj[sum]
			};
		}
	}

	const result = [];

	for (let key in occurrences) {
		if (occurrences.hasOwnProperty(key)) {
			const value = key;
			const { name, count, sum } = occurrences[key];
			result.push({ name, value, count, sum });
		}
	}

	return result;
};

export const formatFormaPagamento = {
	"CARTAO_CREDITO": "Cartão de crédito",
	"CARTAO_DEBITO": "Cartão de débito",
	"DINHEIRO": "Dinheiro",
	"PIX": "PIX",
	"VALE_PRESENTE": "Vale presente",
	"VALE PRESENTE": "Vale presente",
	"CORTESIA": "Cortesia",
	"CONVENIO": "Convênio",
	"OUTROS": "Outros",
	"GALAXPAY": "GalaxPay",
	"CARTAO DE DEBITO": "Cartão de débito",
	"CARTAO DE CREDITO": "Cartão de crédito"
}

export const formatRelatorioAssuidade = (data) => {
	let clientes_formated = [];
	let completed = [];

	Object.keys(data.clientes).forEach((key, i) => {
		data.clientes[key].forEach((obj, j) => {
			clientes_formated.push(obj);
		})
	});

	clientes_formated.forEach((cliente, k) => {
		let search = completed.find(x => x.cliente_id == cliente.age_id_cliente && x.user_id == cliente.age_id_user);
		let new_obj = {};

		if (search) {
			search[cliente.periodo] = search[cliente.periodo] ? search.assuidade + cliente.assuidade : 0 + cliente.assuidade;
		} else {
			new_obj = {
				'cliente_id': cliente.age_id_cliente,
				'cliente_nome': cliente.cli_name,
				'user_id': cliente.age_id_user,
				'user_nome': cliente.usu_name,
			};

			new_obj[cliente.periodo] = cliente.assuidade;

			completed.push(new_obj);
		}
	})

	return completed;
};
